import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Container from '../components/Container'
import { Heading } from '@chakra-ui/react'

const ServicesPage = ({ data }) => {
  return (
    <Layout>
      <SEO title={data.site.siteMetadata.title} />
      <Container mt="175px">
        <h1>H1 example</h1>
        <Heading as="h1" textStyle="h1">H1 example</Heading>
        <h2>H2 example</h2>
        <h3>H3 example</h3>
        <h4>H4 example</h4>
        <h5>H5 example</h5>
        <h6>H6 example</h6>
        <p>Paragraph text</p>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query aloServices {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default ServicesPage
