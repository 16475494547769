import React from 'react'
import { Box } from '@chakra-ui/react'

const Container = ({ children, maxW, style, border, mb, mt }) => {
  return (
	<Box
		maxWidth={maxW || '960px'}
		margin="0 auto"
		padding="0 15px"
		mt={mt}
		mb={mb}
		style={{ ...style }}
		border={border ? '1px dashed purple' : ''}
	>
		{children}
	</Box>
  )
}

export default Container
